import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Problem from '../components/Problem'

const ProblemPage = () => (
    <Layout>
        <SEO title="problem:title" description="problem:description" />
        <Problem />
    </Layout>
)

export default ProblemPage
